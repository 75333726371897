import React from 'react'
import { Link } from 'gatsby'

const NavProjects = ({ prev, next }) => (
  <div className={`nav-preview`}>
    {prev && (
      <Link to={prev.slug} className={`prev-nav`}>
        <span>←</span> {prev.title}
      </Link>
    )}
    {next && (
      <Link to={next.slug} className={`next-nav`}>
        {next.title} <span>→</span>
      </Link>
    )}
  </div>
)

export default NavProjects
